.offcanvas-header{
    background-color: #f2f2f2;
    
}
.offcanvas-body{
    padding: 1rem 0px;
    background-color: #f2f2f2;
    ul{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: start;
        padding: 0;
        margin: 0;
        
        
      li{
        list-style: none;
        a{
            color: #000;
            text-decoration: none;
            position: relative;
            font-weight: bold;
            display: flex;
            align-items: center;
            transition: .5s;
            padding: .5rem 0rem .5rem 1rem;
            &:hover{
                background-color: #ccc;
            }
            &.active{
                &::before{
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: .5rem;
                    background-color: #3e3e3e;
                    left: 0;
                    top: 0;
                    border-radius: 0px 8px 8px 0px;
                }
            }
        }
      }
    }
  }